import copydeck from './copydeck.js'

const CD = copydeck.get;

export default [
  {
    quiz: [
      {
        question: CD('QUESTION_1'),
        answers: [
          {
            answer: CD('Q1_REPONSE_1'),
            profils: "D|G",
          },
          {
            answer: CD('Q1_REPONSE_2'),
            profils: "F|B",
          },
          {
            answer: CD('Q1_REPONSE_3'),
            profils: "A|E",
          },
          {
            answer: CD('Q1_REPONSE_4'),
            profils: "C",
          },
        ],
      },
      {
        question: CD('QUESTION_2'),
        answers: [
          {
            answer: CD('Q2_REPONSE_1'),
            profils: "E|F",
          },
          {
            answer: CD('Q2_REPONSE_2'),
            profils: "A|D",
          },
          {
            answer: CD('Q2_REPONSE_3'),
            profils: "C|B",
          },
          {
            answer: CD('Q2_REPONSE_4'),
            profils: "G",
          },
        ],
      },
      {
        question: CD('QUESTION_3'),
        answers: [
          {
            answer: CD('Q3_REPONSE_1'),
            profils: "C|G",
          },
          {
            answer: CD('Q3_REPONSE_2'),
            profils: "A|F",
          },
          {
            answer: CD('Q3_REPONSE_3'),
            profils: "B|D",
          },
          {
            answer: CD('Q3_REPONSE_4'),
            profils: "E",
          },
        ],
      },
      {
        question: CD('QUESTION_4'),
        answers: [
          {
            answer: CD('Q4_REPONSE_1'),
            profils: "C|F",
          },
          {
            answer: CD('Q4_REPONSE_2'),
            profils: "G|E",
          },
          {
            answer: CD('Q4_REPONSE_3'),
            profils: "A|D",
          },
          {
            answer: CD('Q4_REPONSE_4'),
            profils: "B",
          },
        ],
      },
      {
        question: CD('QUESTION_5'),
        answers: [
          {
            answer: CD('Q5_REPONSE_1'),
            profils: "D|F",
          },
          {
            answer: CD('Q5_REPONSE_2'),
            profils: "E|C",
          },
          {
            answer: CD('Q5_REPONSE_3'),
            profils: "B|G",
          },
          {
            answer: CD('Q5_REPONSE_4'),
            profils: "A",
          },
        ],
      },
      {
        question: CD('QUESTION_6'),
        answers: [
          {
            answer: CD('Q6_REPONSE_1'),
            profils: "E|B",
          },
          {
            answer: CD('Q6_REPONSE_2'),
            profils: "G|C",
          },
          {
            answer: CD('Q6_REPONSE_3'),
            profils: "F|A",
          },
          {
            answer: CD('Q6_REPONSE_4'),
            profils: "D",
          },
        ],
      },
    ],
  },
];
